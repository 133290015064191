import React, { useContext } from 'react';
import { HashRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from './components/pages/Dashboard';
import Login from './components/pages/Login';
import Layout from './components/layouts/Layout';
import Teams from './components/pages/Teams';
import Team from './components/pages/Team';
import Transactions from './components/pages/Transactions';
import Settings from './components/pages/Settings';
import Register from './components/pages/Register';
import Shop from './components/pages/Shop';
import { AuthContext } from './AuthContext';
import { UserContext } from './UserContext';
import Users from './components/pages/Users';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primeicons/primeicons.css';
import Earnings from './components/pages/Earnings';
import Roles from './components/pages/Roles';
import ForgotPassword from './components/pages/ForgotPassword';
import ResetPassword from './components/pages/ResetPassword';
import VerifyEmail from './components/pages/VerifyEmail';
import RegisterInfluencer from './components/pages/RegisterInfluencer';

function App() {

  const { modules } = useContext(UserContext);
  const { isLoggedIn, apiToken } = useContext(AuthContext);

  const ProtectedRoute = ({ element }) => {    
    return isLoggedIn && apiToken && element ? element : <Navigate to="/login" />
  };

  const CheckRoute = ({element}) => {
    return isLoggedIn && apiToken ? <Navigate to="/home" /> : <Navigate to="/login" /> 
  }

  const CheckModule = ({module_name}) => {
    var element = null;
    switch(module_name){
      case 'home': element = <Dashboard/> 
      break;
      case 'teams': element = <Teams/> 
      break;
      case 'view team': element = <Team/> 
      break;
      case 'transactions': element = <Transactions/> 
      break;
      case 'users': element = <Users/> 
      break;
      case 'settings': element = <Settings/> 
      break;
      case 'shop': element = <Shop/> 
      break;
      case 'earnings': element = <Earnings/> 
      break;
      case 'roles': element = <Roles/> 
      break;
    }
    return(
      <ProtectedRoute
        element={<Layout children={element} />}
      />
    );
  } 

  return (
  <Router>
    <Routes>
      <Route path="/*" element={
        <CheckRoute/>
      } /> 
      <Route path="/register" element={<Register />} />
      <Route path="/influencer-registration" element={<RegisterInfluencer />} />
      <Route path="/register/:referrerCode" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} /> 
      <Route path="/reset-password/:token" element={<ResetPassword />} /> 
      <Route path="/verify-email/:token" element={<VerifyEmail />} /> 

      {
        modules && modules.map((m,i) => {
          return(
            <Route key={i}
              path={`/${m.url}`}
              element={
                <CheckModule module_name={m.name.toLowerCase()}/>
              }
            />
          )
        })
      }

    </Routes>
  </Router>
  );
}

export default App;
