import React from 'react';
import { BiHome } from 'react-icons/bi';
import { HashLink as Link } from 'react-router-hash-link';
const BreadCrumb = ({links}) => {
  return (
    <>
      <nav className="flex" aria-label="Breadcrumb">
        <ol className="inline-flex items-center space-x-1 md:space-x-3 text-xl">
          <li className="inline-flex items-center">
            <Link to="/#" className="inline-flex items-center text-white">
              <BiHome size={25} className='mr-2'/>
              Home
            </Link>
          </li>
          {
            links && links.map((d,i) => {
              return(
                <li key={i}>
                  <div className="flex items-center">
                    <span className='text-white'>/</span>
                    <Link to={d.link} className="ml-1 text-white md:ml-2">{d.title}</Link>
                  </div>
                </li>
              )
            })
          }
        </ol>
      </nav>
    </>
  );
};

export default BreadCrumb;