import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useContext, useRef, useState } from "react";
import therest from "../../therest";
import { LoadingContext } from "../../contexts/LoadingContext";

const ForgotPassword = () => {
  const toast = useRef(null); 

  const [userId, setUserId] = useState('');
  const [disable, setDisable] = useState(false); 
  const { setIsShowLoading } = useContext(LoadingContext);

  const handleSubmit = () => {
    if(!disable){
      setIsShowLoading(true);
      setDisable(true);
      therest.post('/forgot-password', {user_id: userId}).then((response) => {
        if(response.status){
          toast.current.show({ severity: 'success', summary: 'Success', detail: 'Password reset link will be sent to the registered email address.'});
          var t = setTimeout(() => {
            setIsShowLoading(false);
            window.location.replace('/');
            clearTimeout(t);
          }, 2000)
        }
      })
    }
  }

  return (
    <>
      <Toast ref={toast} />
      <div className='w-full flex justify-center items-center h-screen bg-BrandBlue lg:p-0 p-4'>
        <div className="lg:w-2/6 w-full lg:p-[40px] p-4 bg-white border border-gray-200 rounded-[16px] shadow">
          <div className="w-full gap-4 flex items-center pb-8 justify-between">
            <h1 className="lg:text-4xl text-xl font-bold text-sky-600">Forgot Password</h1>
          </div>
          <div className="space-y-6">
            <div>
              <label labelfor="id" className="block mb-2 ml-3 text-sm font-medium text-gray-900">Enter your UserID/Referral Code</label>
              <InputText type="text" value={userId} onChange={(e) => setUserId(e.target.value)} aria-autocomplete="false" className='w-full ring-0 border rounded-full px-4 py-3' name="id" id="id" />
            </div>
            <button disabled={disable} onClick={handleSubmit} type="button" className="w-full text-white bg-BrandGreen hover:bg-BrandBlue focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-4 px-4 text-center">Submit</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgotPassword