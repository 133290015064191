import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ReactNotifications } from 'react-notifications-component'

import 'react-notifications-component/dist/theme.css';

import { AuthProvider } from './AuthContext';
import { UserProvider } from './UserContext';
import { SidebarProvider } from './contexts/SidebarContext';
import { LoadingProvider } from './contexts/LoadingContext';

import { PrimeReactProvider } from 'primereact/api';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <ReactNotifications />
    <PrimeReactProvider value={{ pt: {
      datatable: {
        table: {
          className: 'border'
        }
      }
    }, ripple: true }}>
      <AuthProvider>
        <UserProvider>
          <SidebarProvider>
            <LoadingProvider>
              <App />
            </LoadingProvider>
          </SidebarProvider>
        </UserProvider>
      </AuthProvider>
    </PrimeReactProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
