import React from "react";
import Sidebar from "./Sidebar";
import { UserProvider } from "../../UserContext";

const Layout = ({children}) => {
  return (
    <UserProvider>
      <div className="w-full h-screen bg-BrandBlue flex">
        <Sidebar />
        <main className="p-4 w-full pt-8 overflow-y-auto">
          {children}
        </main>
      </div>
    </UserProvider>
  )
}

export default Layout;