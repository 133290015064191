import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useContext, useEffect, useRef, useState } from "react";
import therest from "../../therest";
import { Password } from "primereact/password";
import { useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { LoadingContext } from "../../contexts/LoadingContext";

const ResetPassword = () => {
  const toast = useRef(null); 
  const { token } = useParams();
  const [seconds, setSeconds] = useState(''); 
  const [otpButton, setOtpButton] = useState(true);
  const [timer, setTimer] = useState();
  const [otp, setOtp] = useState();
  const [newPassword, setNewPassword] = useState();
  const { setIsShowLoading } = useContext(LoadingContext);

  var secondsLeft = 300;
  const triggerTimer = () => {
    setOtpButton(false);
    therest.get('/forgot-password/otp/generate/'+token).then(response => {
      if(response.status){
        setTimer(setInterval(() => {
          setSeconds(secondsLeft);
          secondsLeft -= 1;
          if (secondsLeft < 0) {
            clearInterval(timer);
            setTimer(null);
            setOtpButton(true);
          }
        }, 1000));
      } else {
        setOtpButton(true);
      }
    })
  }

  const beforeWithdrawOnHide = () => {
    setOtpButton(true);
    setSeconds('');
    clearInterval(timer);
    setTimer(null);
  }

  useEffect(()=> {
    setIsShowLoading(false);
  });

  const handleSubmit = async () => {
    // validate password
    if(newPassword.length < 6){
      toast.current.show({ severity: 'error', summary: 'Failed!', detail: 'Password minimum length at least 6 characters.' });
      return ;
    }

    therest.post('/forgot-password/otp/verify', {token: token, code: otp, is_password: true}).then((response) => {
      console.log(response);
      setIsShowLoading(true);
      if(response.status){
        therest.post('/forgot-password/password-update', {token: token, new_password: newPassword}).then((res) => {
          if(res.status){
            setIsShowLoading(false);
            toast.current.show({ severity: 'success', summary: 'Success', detail: response.message });

            var t = setTimeout(() => {
              
              window.location.replace('/');
              clearTimeout(t);
            }, 2000)
          } else {
            toast.current.show({ severity: 'error', summary: 'Failed!', detail: response.message });
            setIsShowLoading(false);

          }
        });
      } 
      
      if(response.status == false){
        console.log("this should be caleed!!!!!!!!!!!");
        setIsShowLoading(false);
        toast.current.show({ severity: 'error', summary: 'Failed!', detail: response.message }); 
      }
    });
  }

  return (
    <>
      <Toast ref={toast} />
      <div className='w-full flex justify-center items-center h-screen bg-BrandBlue lg:p-0 p-4'>
        <div className="lg:w-2/6 w-full lg:p-[40px] p-4 bg-white border border-gray-200 rounded-[16px] shadow">
          <div className="w-full gap-4 flex items-center pb-8 justify-between">
            <h1 className="lg:text-4xl text-xl font-bold text-sky-600">Password Reset</h1>
          </div>
          <div className="grid lg:grid-cols-2 gap-4">
            <div className="lg:col-span-2">
              <div className="flex flex-col gap-1">
                <label className="font-bold">New Password</label>
                <Password value={newPassword} onChange={(e) => setNewPassword(e.target.value)} inputClassName='w-full ring-0 border rounded-full px-4 py-3' id="password" name="password" className="w-full" feedback={false} toggleMask />
              </div> 
            </div>
            <div className="lg:col-span-1">
              <div className="flex flex-col gap-1">
                <label className="font-bold" htmlFor="withdrawal_method">One Time PIN</label>
                <InputText autoComplete="false" className="w-full ring-0 border rounded-full px-4 py-3" value={otp} onChange={(e) => setOtp(e.target.value)} keyfilter="int" />
              </div> 
            </div>
            <div className="lg:col-span-1">
              <div className="flex flex-col gap-1">
                <label>&nbsp;</label>
                <Button disabled={!otpButton} onClick={triggerTimer} label={`Get OTP ${!otpButton && seconds != '' ? '('+seconds+'s)' : ''}`} className="px-4 py-3 rounded-full bg-BrandBlue text-white" />
              </div> 
            </div>
            <div className="lg:col-span-2">
              <Button onClick={handleSubmit} label="Submit" className="w-full px-4 py-3 rounded-full bg-BrandGreen text-white" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ResetPassword