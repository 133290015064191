import React, { createContext, useState } from 'react';

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('me_user')));
  const [modules, setModules] = useState(JSON.parse(localStorage.getItem('modules')));
  const updateUser = (userData) => {
    setUser(userData);
    localStorage.setItem('me_user', JSON.stringify(userData));
  };

  const updateModules = (modulesData) => {
    setModules(modulesData);
    localStorage.setItem('modules', JSON.stringify(modulesData));
  }

  return (
    <UserContext.Provider value={{ user, updateUser, modules, updateModules }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };