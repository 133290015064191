import React, { createContext, useState } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dialog } from 'primereact/dialog';

const LoadingContext = createContext();

const LoadingProvider = ({ children }) => {
  const [showLoading, setIsShowLoading] = useState(false);

  return (
    <LoadingContext.Provider value={{ showLoading, setIsShowLoading }}>
      {children}
      <Dialog draggable={false} baseZIndex={999999} visible={showLoading} style={{ width: '250px' }} closable={false} onHide={() => setIsShowLoading(false)}>
        <div className='flex flex-col justify-center gap-4'>
          <ProgressSpinner animationDuration='5s' />
          <p className='text-center'>Please wait...</p>
        </div>
      </Dialog>
    </LoadingContext.Provider>
  );
};

export { LoadingContext, LoadingProvider };