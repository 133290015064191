import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import therest from "../../therest";

const VerifyEmail = () => {
  const toast = useRef(null);
  const { token } = useParams();
  const [verifyEmailText, setVerifyEmailText] = useState('Verifying your email...');
  const [redirectingText, setRedirectingText] = useState('');
  const [seconds, setSeconds] = useState(5);

  const verifyEmail = () => {
    therest.get('/verify-email/' + token).then(response => {

      toast.current.show({ severity: response.status ? 'success' : 'error', summary: response.status ? 'Success' : 'Failed', detail: response.message});
      
      setVerifyEmailText(response.message);
      setRedirectingText('Redirecting to login page');

      let secondsLeft = 5;
      const timer = setInterval(() => {
        setSeconds(secondsLeft);
        secondsLeft -= 1;
        if (secondsLeft < 0) {
          clearInterval(timer);
          window.location.replace('/');
        }
      }, 1000);

    })
  }

  useEffect(() => {
    verifyEmail();
  }, []);

  return(
    <>
      <Toast ref={toast} />
      <div className='w-full flex justify-center items-center h-screen bg-BrandBlue lg:p-0 p-4'>
        <div className="lg:w-2/6 w-full lg:p-[40px] p-4 bg-white border border-gray-200 rounded-[16px] shadow">
          <div className="w-full">
            <h1 className="lg:text-4xl text-xl font-bold text-sky-600">{verifyEmailText}</h1>
            {
              redirectingText && (
                <p>{redirectingText} in {seconds}</p>
              )
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default VerifyEmail;