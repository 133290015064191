import BreadCrumb from "../shared/BreadCrumb"

const Roles = () => { 

  return (
    <>
      <BreadCrumb links={[{title: 'Settings', link: '/settings'}]}/>
      <div className="w-full min-h-[50%] relative bg-white rounded-lg mt-8">
        <div className="w-full gap-4 flex items-center p-4 lg:p-8 pb-0 justify-between">
          <h1 className="lg:text-4xl text-xl font-bold text-sky-600">Roles</h1>
        </div>
        <div className="mb-5 px-4 lg:px-8 pb-8">
          
        </div>
      </div>
    </> 
  )
}

export default Roles